import React from "react"
import Layout from "../components/layout"

export default function About() {
  return (
  <Layout>
    <div>
      <h1>About me</h1>
      <p>
        I’m good enough, I’m smart enough, and gosh darn it, people like me!
      </p>
    </div>
  </Layout>
  )
}
